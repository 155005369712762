<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card
            color="success"
            icon="mdi-account-group"
            inline
            title="Groups"
        >
          <v-icon class="section-helper" @click="openHelpLink('groups')">mdi-help-circle</v-icon>

          <v-row no-gutters>
            <v-col cols="12" md="12">

              <data-grid
                  :envSelected="envSelected"
                  :grid="grid"
                  @doAction="gridAction"
                  @paginate="listGroups"
              >

                <template v-slot:column-name="{ item }">
                  <div class="font-weight-medium">{{ item.name }}</div>
                  <span class="font-weight-light mt-1 font-italic grey--text">({{ item.code }})</span>
                </template>

                <template v-slot:column-description="{ item }">
                  {{ item.description }}
                </template>

                <template v-slot:column-expanded="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="ma-5">
                      <template v-if="Object.keys(item.packages).length > 0">
                        <h4>ACL Permissions</h4>
                        <span class="font-weight-medium"
                              v-for="(packageInfo, onePackage) in item.packages"
                              :key="onePackage">

                    <v-hover
                        v-slot:default="{ hover }"
                        open-delay="50"
                    >
                      <v-chip
                          @click="goToPackage(onePackage)"
                          class="mt-2 mr-2 mb-2"
                          :color="hover ? 'primary white--text': 'orange white--text'"
                      >
                      <v-avatar left>
                        <v-icon>mdi-lock</v-icon>
                      </v-avatar>
                      {{ onePackage }}
                      <help-circle :context="help.rbac"></help-circle>
                    </v-chip>
                    </v-hover>
                  </span>
                      </template>
                      <template v-else>
                        <p class="font-italic font-weight-light">No Access Level Packages linked to this group.</p>
                      </template>
                    </div>
                  </td>
                </template>

              </data-grid>
            </v-col>

          </v-row>

        </base-material-card>

      </v-col>
    </v-row>

    <clone-dialog
        :envSelected="envSelected"
        :options="cloneDialog"
        :doAction="doClone"
    ></clone-dialog>

<!--    <custom-dialog :dialog="usersDialog" :env-selected="envSelected" :do-action="updateUsersGroup">-->
<!--      <v-row v-if="usersDialog.data">-->
<!--        <v-col cols="12">-->
<!--          <v-autocomplete-->
<!--              v-model="usersDialog.data.group.users"-->
<!--              :items="usersDialog.data.users"-->
<!--              :loading="usersDialog.loading"-->
<!--              :search-input.sync="usersDialog.search"-->
<!--              label="Users"-->
<!--              :hint="`Select the users of this group`"-->
<!--              item-value="id"-->
<!--              item-text="label"-->
<!--              hide-no-data-->
<!--              hide-selected-->
<!--              placeholder="Type the name of the user you want to add"-->
<!--              prepend-icon="mdi-account-multiple"-->
<!--              return-object-->
<!--              multiple-->
<!--              persistent-hint-->
<!--              chips-->
<!--              deletable-chips-->
<!--              clearable-->
<!--              @change="usersDialog.search = ''"-->
<!--          >-->
<!--            <template v-slot:selection="{ index, item }">-->
<!--              <v-chip color="primary" v-if="index === 0">-->
<!--                {{ item.label }}-->
<!--              </v-chip>-->
<!--              <span-->
<!--                  v-if="index === 1"-->
<!--                  class="grey&#45;&#45;text caption"-->
<!--              >-->
<!--                (+{{-->
<!--                  usersDialog.data.group.users.length - 1-->
<!--                }} other{{ usersDialog.data.group.users.length > 2 ? 's' : '' }})-->
<!--              </span>-->
<!--            </template>-->
<!--          </v-autocomplete>-->
<!--          <v-expansion-panels class="mt-5 mx-0" focusable-->
<!--                              v-if="usersDialog.data.group.users && usersDialog.data.group.users.length > 0">-->
<!--            <v-expansion-panel>-->
<!--              <v-expansion-panel-header>-->
<!--                Users in this Group ( {{ usersDialog.data.group.users.length }} )-->
<!--              </v-expansion-panel-header>-->
<!--              <v-expansion-panel-content class="pt-4">-->
<!--                <v-chip label color="primary" class="ma-1" v-for="(user, i) in usersDialog.data.group.users"-->
<!--                        :key="i">-->
<!--                  <v-icon class="mr-1">mdi-account</v-icon>-->
<!--                  {{ user.label }}-->
<!--                  <v-icon class="ml-2" right @click="removeUsersFromGroup(user)">mdi-delete</v-icon>-->
<!--                </v-chip>-->
<!--              </v-expansion-panel-content>-->
<!--            </v-expansion-panel>-->
<!--          </v-expansion-panels>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--    </custom-dialog>-->

  </v-container>
</template>

<script>

import CloneDialog from "@/views/internal/components/custom/cloneDialog"
import globalMixins from "../../../../mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";
import dataGrid from "@/views/internal/components/custom/dataGrid";
import HelpCircle from "@/views/internal/components/custom/helpCircle";

export default {
  name: 'Groups',

  mixins: [globalMixins, fieldsMixins],

  data: () => ({
    help: {
      rbac: {
        bottom: true,
        text: `Configure RBAC`,
        link: 'rbac'
      }
    },
    grid: {
      fieldAccess: {},
      //mandatory
      headers: [
        {
          text: '',
          value: 'data-table-expand'
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          align: 'right',
          text: 'Actions',
          value: 'actions',
        },
      ],
      items: [],
      selected: [],
      //optional
      loading: true,
      //optional
      columns: [
        {
          name: 'item.name'
        },
        {
          name: 'item.description'
        },
        {
          name: 'item.expanded'
        }
      ],
      //optional
      search: {
        keyword: ''
      },
      //optional
      itemID: 'id',
      //optional
      expand: {
        single: false,
        items: []
      },
      //optional
      multi: [
        {
          icon: 'delete',
          label: 'Delete',
          color: 'error',
          acl: {
            method: 'delete',
            route: `/organization/groups/:id`
          },
          method: 'deleteGroup',
          //optional
          confirm: `Are you sure you want to delete these group(s)?`
        }
      ],
      //optional
      sortingList: ['name', 'description'],
      sort: ['name', 1, 'description', 1],
      //optional
      headActions: [
        {
          icon: 'refresh',
          label: '',
          color: 'purple',
          acl: {
            method: 'get',
            route: `/organization/groups`
          },
          method: 'listGroups'
        },
        {
          icon: 'plus',
          label: 'create',
          color: 'success',
          acl: {
            method: 'put',
            route: `/organization/groups`
          },
          method: 'addGroup'
        }
      ],
      //optional
      rowActions: [
        // {
        //   method: 'addRemoveusersGroup',
        //   color: 'warning',
        //   icon: 'mdi-account-group',
        //   allow: {
        //     method: 'patch',
        //     route: '/organization/groups/:id/users'
        //   },
        //   tooltip: "Add / Remove Users"
        // },
        {
          method: 'cloneGroup',
          color: 'secondary',
          icon: 'mdi-content-copy',
          allow: {
            method: 'post',
            route: '/organization/groups/:id/copy'
          },
          tooltip: "Clone"
        },
        {
          method: 'editGroup',
          color: 'success',
          icon: 'mdi-pencil',
          allow: {
            method: 'patch',
            route: '/organization/groups/:id'
          },
          tooltip: "Edit"
        },
        {
          method: 'deleteGroup',
          color: 'error',
          icon: 'mdi-delete',
          allow: {
            method: 'delete',
            route: '/organization/groups/:id'
          },
          tooltip: "Delete"
        },
      ]
    },
    cloneDialog: {
      trigger: false,
      original: {},
      actions: [
        {
          color: 'success',
          icon: 'content-copy',
          label: 'Clone',
          method: 'doClone',
          allow: {
            method: 'post',
            route: '/organization/groups/:id/copy'
          }
        }
      ]
    },
    usersDialog: {
      title: '',
      search: '',
      trigger: false,
      loading: false,
      data: null,
      actions: [
        // {
        //   method: 'updateUsersGroup',
        //   icon: 'content-save',
        //   color: 'success',
        //   label: 'Update Group',
        //   allow: {
        //     method: 'patch',
        //     route: '/organization/groups/:id/users'
        //   }
        // }
      ]
    },
  }),

  props: {
    envSelected: {
      type: Object
    }
  },

  components: {
    HelpCircle,
    dataGrid,
    CloneDialog
  },

  methods: {

    async listGroups(page, limit, sort) {
      this.grid.fieldAccess = this.groupListAccess();
      this.grid.loading = true;
      this.grid.items = [];

      if (page !== undefined) {
        this.grid.page = page;
      }
      if (limit !== undefined) {
        this.grid.limit = limit;
      }
      if (sort !== undefined) {
        this.grid.sort = sort;
      }

      const groups = await this.getSendData({
        noLoading: true,
        url: `/consoleapi/organization/groups`,
        method: "get",
        params: {
          offset: (this.grid.page !== undefined) ? this.grid.page : 1,
          limit: (this.grid.limit !== undefined) ? this.grid.limit : 10,
          sort: this.grid.sort
        }
      });
      this.grid.items = groups.items || [];
      this.grid.total = groups.total || 1;
      this.grid.perPage = this.grid.limit;
      this.grid.loading = false;
    },

    //grid action hook
    gridAction(action, item, extra) {
      if (item) {
        this[action](item, extra);
      } else {
        this[action](extra);
      }
    },

    addGroup() {
      this.goToPage({route: 'addGroup', params: {envCode: this.envSelected.value}});
    },

    editGroup(item) {
      this.goToPage({route: 'editGroup', params: {envCode: this.envSelected.value, id: item.id}});
    },

    deleteGroup(item, multi) {
      const _self = this;
      if (multi || (!multi && confirm(`Are you sure you want to delete group: ${item.name}`))) {
        doDelete();
      }

      function doDelete() {
        const apiOptions = {
          url: `/consoleapi/organization/groups/${item.id}`,
          method: "delete"
        };
        _self.getSendData(apiOptions).then(() => {
          _self.pushMessage({
            type: 'success',
            title: `Group Deleted`,
            text: `This group has been deleted.`
          });
          setTimeout(() => {
            _self.listGroups();
          }, 2000);
        });
      }
    },

    goToPackage(oneRole) {
      this.goToPage({
        route: 'editRoleByCode', params: {code: oneRole, envCode: this.envSelected.value}
      });
    },

    //clone
    cloneGroup(item) {
      this.cloneDialog.original = this._lodash.cloneDeep(item);
      this.cloneDialog.trigger = true;
    },

    doClone(value, item) {
      const self = this;
      const apiOptions = {
        url: `/consoleapi/organization/groups/${item.id}/copy`,
        method: "post",
        params: {name: value}
      };
      this.cloneDialog.trigger = false;
      self.getSendData(apiOptions).then((response) => {
        self.pushMessage({
          type: 'success',
          title: `Group Copied`,
          text: `Group ${item.name} has been copied to ${value}.`
        });
        setTimeout(() => {
          self.listGroups();
        }, 2000);
      });
    },

    // async addRemoveusersGroup(item) {
    //   this.usersDialog.loading = true;
    //   let myGroup = this._lodash.cloneDeep(item);
    //   this.listUsers(myGroup, (users) => {
    //     this.usersDialog.loading = false;
    //     // this.usersDialog.search = '';
    //     this.usersDialog.width = 800;
    //     this.usersDialog.title = `Add/Remove Group User(s) from ${myGroup.name}`;
    //     this.usersDialog.data = {
    //       original: item,
    //       group: myGroup,
    //       users: users
    //     };
    //     this.usersDialog.trigger = true;
    //     this.usersDialog.itemId = myGroup.id;
    //   });
    // },

    async searchUsers(search) {
      this.usersDialog.loading = true;
      this.usersDialog.data.users = [];
      this.listUsers(this.usersDialog.data.original, (users) => {
        this.usersDialog.data.users = users;
        this.usersDialog.loading = false;
      });
    },

    async listUsers(myGroup, cb) {
      let data = {items: []};
      if (this.usersDialog.search && this.usersDialog.search.trim() !== '') {
        data = await this.getSendData({
          noLoading: true,
          url: `/consoleapi/organization/users`,
          method: "get",
          params: {
            search: this.usersDialog.search,
            fields: ['firstName', 'lastName', 'email', 'groups'],
            pagination: false
          }
        });
      } else {
        data = await this.getSendData({
          noLoading: true,
          url: `/consoleapi/organization/users`,
          method: "get",
          params: {
            fields: ['firstName', 'lastName', 'email', 'groups'],
            pagination: false
          }
        });
      }

      let users = data.items || [];
      myGroup.users = [];

      users.forEach((oneUser) => {
        if (oneUser.groups && oneUser.groups.includes(myGroup.code)) {
          myGroup.users.push({
            id: oneUser.id,
            label: `${oneUser.firstName} ${oneUser.lastName} < ${oneUser.email} >`
          });
        }
      });

      for (let i = 0; i < users.length; i++) {
        users[i] = {
          id: users[i].id,
          label: `${users[i].firstName} ${users[i].lastName} < ${users[i].email} >`
        };
      }
      return cb(users);
    },

    // removeUsersFromGroup(user) {
    //   const _self = this;
    //   let originalUsers = [...this.usersDialog.data.group.users];
    //   if (confirm(`Are you sure you want to remove user '${user.label}' from this group ?`)) {
    //     let users = [...this.usersDialog.data.group.users];
    //     this.usersDialog.data.group.users = [];
    //     for (let i = users.length - 1; i >= 0; i--) {
    //       if (users[i].id === user.id) {
    //         users.splice(i, 1);
    //       }
    //     }
    //
    //     const apiOptions = {
    //       url: `/consoleapi/organization/groups/${this.usersDialog.data.group.id}/users`,
    //       method: "delete",
    //       body: {
    //         "users": [user.id]
    //       }
    //     };
    //     _self.getSendData(apiOptions)
    //         .then((response) => {
    //           _self.pushMessage({
    //             type: 'success',
    //             title: `Group Updated`,
    //             text: `The list of users in group ${this.usersDialog.data.group.name} has been updated.`
    //           });
    //
    //           this.$nextTick(() => {
    //             this.usersDialog.data.group.users = users;
    //           });
    //
    //           setTimeout(() => {
    //             _self.listGroups();
    //           }, 2000);
    //         })
    //         .catch((error) => {
    //           this.$nextTick(() => {
    //             this.usersDialog.data.group.users = originalUsers;
    //           });
    //         // this.usersDialog.trigger = false;
    //     });
    //
    //   }
    // },
    //
    // updateUsersGroup(value, item) {
    //   const _self = this;
    //   let users = this._lodash.cloneDeep(this.usersDialog.data.group.users);
    //   let myGroup = this._lodash.cloneDeep(this.usersDialog.data.group)
    //   myGroup.users = [];
    //   users.forEach((oneUser) => {
    //     myGroup.users.push(oneUser.id);
    //   });
    //
    //   const apiOptions = {
    //     url: `/consoleapi/organization/groups/${myGroup.id}/users`,
    //     method: "put",
    //     params: {
    //       "users": myGroup.users
    //     }
    //   };
    //   _self.getSendData(apiOptions).then(() => {
    //     _self.pushMessage({
    //       type: 'success',
    //       title: `Group Updated`,
    //       text: `The list of users in group ${myGroup.name} has been updated.`
    //     });
    //     setTimeout(() => {
    //       _self.listGroups();
    //     }, 2000);
    //   });
    // }
  },

  watch: {
    async 'usersDialog.search'(val) {
      if (val && val.length >= 3) {
        this.usersDialog.search = val;
        await this.searchUsers(val);
      }
    }
  }
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
